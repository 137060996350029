@font-face {
    font-family: "qilin-icon"; /* Project id 3741619 */
    src: url('../icons/iconfont.woff2?t=1667270924995') format('woff2'),
         url('../icons/iconfont.woff?t=1667270924995') format('woff'),
         url('../icons/iconfont.ttf?t=1667270924995') format('truetype');
  }

  [class^="qilin-icon"],[class*="qilin-icon"] {
    font-family: "qilin-icon" !important;
    font-size: inherit;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .qilin-icon-arrow-left:before {
    content: "\e664";
  }
  
  .qilin-icon-camera:before {
    content: "\e600";
  }
  
  .qilin-icon-user-female:before {
    content: "\e601";
  }
  
  .qilin-icon-users:before {
    content: "\e602";
  }
  
  .qilin-icon-user-male:before {
    content: "\e6a2";
  }
  
  .qilin-icon-wechat:before {
    content: "\e608";
  }
  
  .qilin-icon-loading:before {
    content: "\e699";
  }
  
  .qilin-icon-refresh:before {
    content: "\e8bd";
  }
  
  .qilin-icon-arrow-top:before {
    content: "\e62c";
  }
  
  .qilin-icon-arrow-right:before {
    content: "\e62d";
  }
  
  .qilin-icon-arrow-down:before {
    content: "\e62e";
  }
  
  .qilin-icon-QQ:before {
    content: "\e882";
  }
  
  .qilin-icon-photo:before {
    content: "\e8ba";
  }
  
  .qilin-icon-phone:before {
    content: "\e8c3";
  }
  
  .qilin-icon-menu:before {
    content: "\e60e";
  }
  
  .qilin-icon-download:before {
    content: "\e856";
  }
  
  