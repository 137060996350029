/*
*   qilin组件通用类名less样式类
*/

@import "../../assets/less/_base.less";
@import "../../assets/less/element.less";
@import "../../qilin-web/css/qilin-icon.less";

// 外边距、内边距辅助类
.distance(@num,@attribute){
    .mixin(@attribute) when (@attribute = m){
        .qilin-@{attribute}-@{num}{
            margin:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = ml){
        .qilin-@{attribute}-@{num}{
            margin-left:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mr){
        .qilin-@{attribute}-@{num}{
            margin-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mt){
        .qilin-@{attribute}-@{num}{
            margin-top:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mb){
        .qilin-@{attribute}-@{num}{
            margin-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mx){
        .qilin-@{attribute}-@{num}{
            margin-left:@mp-mini * @num !important;
            margin-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = my){
        .qilin-@{attribute}-@{num}{
            margin-top:@mp-mini * @num !important;
            margin-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = p){
        .qilin-@{attribute}-@{num}{
            padding:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pl){
        .qilin-@{attribute}-@{num}{
            padding-left:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pr){
        .qilin-@{attribute}-@{num}{
            padding-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pt){
        .qilin-@{attribute}-@{num}{
            padding-top:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pb){
        .qilin-@{attribute}-@{num}{
            padding-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = px){
        .qilin-@{attribute}-@{num}{
            padding-left:@mp-mini * @num !important;
            padding-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = py){
        .qilin-@{attribute}-@{num}{
            padding-top:@mp-mini * @num !important;
            padding-bottom:@mp-mini * @num !important;
        }
    }
    // 执行函数
    .mixin(@attribute);
};

// 疑似新版本被弃用
// .loop();
// .loop(@i:0) when (@i <= 20){
// 	.distance(@i,m);
// 	.distance(@i,ml);
// 	.distance(@i,mr);
// 	.distance(@i,mt);
// 	.distance(@i,mb);
// 	.distance(@i,mx);
// 	.distance(@i,my);
// 	.distance(@i,p);
// 	.distance(@i,pl);
// 	.distance(@i,pr);
// 	.distance(@i,pt);
// 	.distance(@i,pb);
// 	.distance(@i,px);
// 	.distance(@i,py);
// 	.loop(@i+1);
// };


each(range(20),{
    .distance(@value,m);
	.distance(@value,ml);
	.distance(@value,mr);
	.distance(@value,mt);
	.distance(@value,mb);
	.distance(@value,mx);
	.distance(@value,my);
	.distance(@value,p);
	.distance(@value,pl);
	.distance(@value,pr);
	.distance(@value,pt);
	.distance(@value,pb);
	.distance(@value,px);
	.distance(@value,py);
});


// 普通样式文本
.qilin-text-info{
    color:@color-text-info !important;
}
// 成功样式文本
.qilin-text-success{
    color:@color-text-success !important;
}
// 警告样式文本
.qilin-text-warning{
    color:@color-text-warning !important;
}
// 错误样式文本
.qilin-text-error{
    color:@color-text-error !important;
}
// 普通样式背景色
.qilin-bg-info{
    background-color:@color-text-shadow-info !important;
    color:@color-text-info !important;
}
// 成功样式背景色
.qilin-bg-success{
    background-color:@color-text-shadow-success !important;
    color:@color-text-success !important;
}
// 警告样式背景色
.qilin-bg-warning{
    background-color:@color-text-shadow-warning !important;
    color:@color-text-warning !important;
}
// 错误样式背景色
.qilin-bg-error{
    background-color:@color-text-shadow-error !important;
    color:@color-text-error !important;
}
// 通用面板动态样式
.qilin-panel{
    background-color:var(--themeBgColor) !important;
    border-color:var(--themeBorderColor) !important;
}


// 圆角样式
.qilin-radius-default{
    border-radius:@radius-default !important;
}
.qilin-radius-round{
    border-radius:@radius-round !important;
}
.qilin-radius-circle{
    border-radius:@radius-circle !important;
}

// 字体加粗等样式
.qilin-text-bolder{
    font-weight:bolder !important;
}
.qilin-text-normal{
    font-weight:normal !important;
}
.qilin-text-lighter{
    font-weight:lighter !important;
}

// 背景阴影样式
.qilin-shadow{
    box-shadow:@rem2 @rem2 @rem5 var(--shadow) !important;
}

.qilin-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(255,255,255,.8);
    z-index:3000;
    > .qilin-loading-box {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        > i {
            .EDGQilinWH(@rem50,@rem50);
            font-size: @rem40;
            line-height:@rem50;
            animation: loading-rotate 2s linear infinite;
            color:@color-text-info;
        }
        > .qilin-loading-box-text {
            color: @color-text-title;
            font-size: @size-text-default;
            margin-top:@mp-small;
        }
    }
}
@keyframes loading-rotate {
    100% {
        transform: rotate(360deg);
    }
}
