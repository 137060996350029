@import "./_base.less";

.el-select-dropdown__wrap{
    max-height:160px;
}

/*
    el-card样式修改
*/
.el-card{
    margin-bottom:@rem15;
    >.el-card__header{
        color:@color-text-common;
        font-weight:bold;
        font-size:@size-text-larger;
    }
    >.el-card__body{
        padding-bottom:@rem10;
        .el-collapse{
            border-top:none;
            border-bottom:none;
            .el-collapse-item__header{
                border-bottom:none;
                height:36px;
                line-height:36px;
            }
            .el-collapse-item__wrap{
                border-bottom:none;
                >.el-collapse-item__content{
                    padding:0 0 @rem10 @rem20;
                    color:@color-text-common;
                }
            }
        }
    }
}

/*
    el-tabs样式修改
*/
.el-tabs{
    &.tagsView{
        font-size:@size-text-default !important;
        border-bottom-width: 0;
        border-radius: @radius-default;
        background-color:@color-theme-common-dark;
        padding-right:@rem35;
        >.el-tabs__header{
            border-bottom-width:0;
            background-color:@color-theme-common-dark;
            border-radius: @radius-default;
            margin-bottom:0;
            >.el-tabs__nav-wrap{
                border-radius: @radius-default;
                margin-bottom:0;
                >.el-tabs__nav-prev,>.el-tabs__nav-next{
                    font-size:@size-text-medium;
                    color:@color-text-title-checked;
                    >i{
                        font-weight:bolder;
                    }
                }
            }
            .el-tabs__nav{
                border:none;
            }
            .el-tabs__item{
                &:last-of-type{
                    border-right:1px solid @color-border;
                }
                border-bottom-width:0;
                color:@color-text-title;
                font-weight:bolder;
                background-color:@color-theme-common-dark;
                >a{
                    color:@color-text-title;
                    &.is-active{
                        color:@color-text-title-checked;
                    }
                    &:hover{
                        color:@color-text-title-checked;
                    }
                }
                >span{
                    font-weight:bolder;
                    &:hover{
                        background-color:transparent;
                    }
                }
                &.is-active{
                    >.el-icon-close{
                        width:0;
                    }
                    &:hover{
                        >.el-icon-close{
                            width:14px;
                        }
                    }
                }
            }
        }
        .el-tabs__content{
            display:none;
        }
    }
    .el-tabs__item{
        font-size:@size-text-default !important;
    }
}

/*
    el-dialog样式修改
*/
.el-dialog__wrapper{
    overflow:hidden;
    // padding-top:15vh;
}
.el-dialog{
    .el-dialog__header{
        color:@color-text-title;
        font-size:@size-text-medium;
        font-weight:bolder;
        i{
            font-size:@size-text-medium;
            font-weight:bolder;
        }
    }
    .el-form{
        .el-date-editor.el-input{
            width:100%;
        }
        .el-upload--picture-card{
            line-height:110px;
        }
        .el-upload--picture-card,.el-upload-list__item{
            .EDGQilinWH(100px,100px);
            >.el-upload-list__item-actions{
                line-height:100px;
            }
        }
    }
}

/*
    el-menu样式修改
*/
.el-menu{
    .el-menu-item,i,.el-submenu__title{
        font-size:@size-text-default !important;
    }
}

/*
    el-dropdown样式修改
*/
.el-dropdown-menu{
    font-size:@size-text-default !important;
    .el-dropdown-menu__item{
        font-size:@size-text-default !important;
    }
}

/*
    el-date-editor样式修改
*/
.el-date-editor{
    .el-range-separator{
        width:8%;
    }
}

/*
    el-form样式修改
*/
.el-form{
    
}

/*
    el-table样式修改
*/
.el-table{
    *{
        font-size:@size-text-small !important;
    }
}

/*
    el-button样式修改
*/
.el-button{
    font-size:@size-text-small !important;
}