/*
*   qilin组件库中常用的less样式变量设置
*/

// 字体颜色
@color-text-common:#657180; //默认正文内容字体颜色
@color-text-title:#17233d; //默认标题字体颜色
@color-text-title-checked:#fff; //选中的标题字体颜色
@color-text-invalid:#c3cbd6; //失效字体颜色
@color-text-support:#808080; //辅助字体颜色

// 背景色
@color-theme-common-dark:#2d8cf0; //默认主题颜色-深
@color-theme-common-light:#5cadff; //默认主题颜色-浅
@color-bg-common:#fff; //常用背景颜色
@color-bg-title:#FAFAFA; //标题背景颜色

// 边框颜色
@color-border:#efefef; //表格边框颜色

// 阴影颜色
@color-shadow:rgba(0,0,0,0.2);

// 状态颜色
@color-text-info:#2d8cf0; //信息提示颜色
@color-text-shadow-info:rgb(45, 140, 240,0.5); //信息提示背景颜色
@color-text-success:#19be6b; //成功颜色
@color-text-shadow-success:rgb(25, 190, 107,0.5); //成功背景颜色
@color-text-warning:#ff9900; //警告颜色
@color-text-shadow-warning:rgb(255, 153, 0,0.5); //警告背景颜色
@color-text-error:#ed4014; //失败颜色
@color-text-shadow-error:rgb(237, 64, 20,0.5); //失败背景颜色

// 定义基础文字大小变量--根文件中设置html定义字体大小为16px * 87.5% = 14px，下面皆用rem换算
@size-text-mini:0.714rem; //超小--10/14
@size-text-small:0.857rem; //小型--12/14
@size-text-default:1rem; //默认大小--14/14
@size-text-medium:1.143rem; //中等大小--16/14
@size-text-large:1.286rem; //大型--18/14
@size-text-larger:1.714rem; //超大型--24/14

// 定义一些常用的尺寸变量
@rem2:0.142rem; //2px
@rem5:0.357rem; //5px
@rem10:0.714rem; //10px
@rem15:1.071rem; //15px
@rem20:1.429rem; //20px
@rem25:1.786rem; //25px
@rem30:2.143rem; //30px
@rem35:2.5rem; //35px
@rem40:2.857rem; //40px
@rem45:3.214rem; //45px
@rem50:3.571rem; //50px

// 定义基础文字字体变量
@text-china-default:"Hiragino Sans GB";
@text-english-default:"Helvetica";

// 常用的margin与padding距离
@mp-mini:0.357rem; // 5/14
@mp-small:0.714rem; //10/14
@mp-medium:1.071rem; //15/14
@mp-large:1.429rem; //20/14

// 常用高度值
@height-mini:1rem;
@height-small:1.714rem; //24/14
@height-medium:2.143rem; //30/14
@height-large:2.857rem; //40/14

// 常用圆角值
@radius-default:0.286rem; //4/14
@radius-round:999rem;
@radius-circle:50%;

// 设置水平+垂直样式
.EDGQilinFlex(@justify:center,@align:center) {
    display:flex;
    flex-flow:row nowrap;
    justify-content:@justify;
    align-items:@align;
}

// 设置通配符样式
.EDGQilinGeneral {
    padding:0;
    margin:0;
    box-sizing: border-box;
}

// 设置宽高样式
.EDGQilinWH(@width:100%;@height:100%) {
    width:@width;
    height:@height;
}

// 设置背景图片样式
.EDGQilinBGImage(@url,@size:cover,@repeat:no-repeat) {
    background-image:~"url(@{url})";
    background-size:@size;
	background-repeat:@repeat;
}

// 设置定位样式
.EDGQilinPosition(@position:absolute,@top:50%,@left:50%) {
    position:@position;
    top:@top;
    left:@left;
}