/*
*   qilin组件通用类名less样式类
*/

@import "../../../assets/less/_base.less";
@import "../../../assets/less/element.less";
@import "../../../qilin-web/css/qilin-icon.less";

// 外边距、内边距辅助类
.distance(@num,@attribute){
    .mixin(@attribute) when (@attribute = m){
        .qilin-@{attribute}-@{num}{
            margin:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = ml){
        .qilin-@{attribute}-@{num}{
            margin-left:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mr){
        .qilin-@{attribute}-@{num}{
            margin-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mt){
        .qilin-@{attribute}-@{num}{
            margin-top:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mb){
        .qilin-@{attribute}-@{num}{
            margin-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mx){
        .qilin-@{attribute}-@{num}{
            margin-left:@mp-mini * @num !important;
            margin-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = my){
        .qilin-@{attribute}-@{num}{
            margin-top:@mp-mini * @num !important;
            margin-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = p){
        .qilin-@{attribute}-@{num}{
            padding:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pl){
        .qilin-@{attribute}-@{num}{
            padding-left:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pr){
        .qilin-@{attribute}-@{num}{
            padding-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pt){
        .qilin-@{attribute}-@{num}{
            padding-top:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pb){
        .qilin-@{attribute}-@{num}{
            padding-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = px){
        .qilin-@{attribute}-@{num}{
            padding-left:@mp-mini * @num !important;
            padding-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = py){
        .qilin-@{attribute}-@{num}{
            padding-top:@mp-mini * @num !important;
            padding-bottom:@mp-mini * @num !important;
        }
    }
    // 执行函数
    .mixin(@attribute);
};

// 疑似新版本被弃用
// .loop();
// .loop(@i:0) when (@i <= 20){
// 	.distance(@i,m);
// 	.distance(@i,ml);
// 	.distance(@i,mr);
// 	.distance(@i,mt);
// 	.distance(@i,mb);
// 	.distance(@i,mx);
// 	.distance(@i,my);
// 	.distance(@i,p);
// 	.distance(@i,pl);
// 	.distance(@i,pr);
// 	.distance(@i,pt);
// 	.distance(@i,pb);
// 	.distance(@i,px);
// 	.distance(@i,py);
// 	.loop(@i+1);
// };


each(range(20),{
    .distance(@value,m);
	.distance(@value,ml);
	.distance(@value,mr);
	.distance(@value,mt);
	.distance(@value,mb);
	.distance(@value,mx);
	.distance(@value,my);
	.distance(@value,p);
	.distance(@value,pl);
	.distance(@value,pr);
	.distance(@value,pt);
	.distance(@value,pb);
	.distance(@value,px);
	.distance(@value,py);
});


// 普通样式文本
.qilin-text-info{
    color:@color-text-info !important;
}
// 成功样式文本
.qilin-text-success{
    color:@color-text-success !important;
}
// 警告样式文本
.qilin-text-warning{
    color:@color-text-warning !important;
}
// 错误样式文本
.qilin-text-error{
    color:@color-text-error !important;
}
// 普通样式背景色
.qilin-bg-info{
    background-color:@color-text-shadow-info !important;
    color:@color-text-info !important;
}
// 成功样式背景色
.qilin-bg-success{
    background-color:@color-text-shadow-success !important;
    color:@color-text-success !important;
}
// 警告样式背景色
.qilin-bg-warning{
    background-color:@color-text-shadow-warning !important;
    color:@color-text-warning !important;
}
// 错误样式背景色
.qilin-bg-error{
    background-color:@color-text-shadow-error !important;
    color:@color-text-error !important;
}
// 通用面板动态样式
.qilin-panel{
    background-color:var(--themeBgColor) !important;
    border-color:var(--themeBorderColor) !important;
}


// 圆角样式
.qilin-radius-default{
    border-radius:@radius-default !important;
}
.qilin-radius-round{
    border-radius:@radius-round !important;
}
.qilin-radius-circle{
    border-radius:@radius-circle !important;
}

// 字体加粗等样式
.qilin-text-bolder{
    font-weight:bolder !important;
}
.qilin-text-normal{
    font-weight:normal !important;
}
.qilin-text-lighter{
    font-weight:lighter !important;
}

// 背景阴影样式
.qilin-shadow{
    box-shadow:@rem2 @rem2 @rem5 var(--shadow) !important;
}

.qilin-QilinView{
    >.qilin-QilinView-row{
        border:1px solid @color-border;
        border-left:none;
        overflow:hidden;
        border-radius:6px;
        >.qilin-QilinView-col{
            border:1px solid @color-border;
            border-left:none;
            border-right:none;
            border-bottom:none;
            margin-top:-1px;
            margin-bottom:0;
            display:flex;
            &:first-of-type{
                border-radius:6px 0 0 0;
                >.qilin-QilinView-col-label{
                    border-radius:6px 0 0 0;
                }
            }
            // &:last-of-type{
            //     margin-bottom:-1px;
            // }
            >.qilin-QilinView-col-label{
                border-left:1px solid @color-border;
                border-right:1px solid @color-border;
                padding: 10px 12px;
                width: 50%;
                background-color:@color-bg-title;
                color:@color-text-title;
                font-weight:bolder;
                display:flex;
                align-items:center;
                &>span.ellipsis{
                    display: inline-block;
                    width:100%;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                }
            }
            >.qilin-QilinView-col-value{
                padding:10px;
                color:@color-text-common;
                width:50%;
                overflow:hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
                >span{
                    margin:0 10px 10px 0;
                    >.el-image{
                        width:80px;
                        height:80px;
                        border-radius:5px;
                    }
                }
                >.textarea{
                    display:inline-block;
                    width:100%;
                    height:100px;
                }
                &.autoValue{
                    white-space: normal;
                }
            }
            &.el-col-24{
                >label{
                    width:16.665%;
                }
                >span{
                    width:83.335%;
                }
            }
            &.el-col-16{
                >label{
                    width:25%;
                }
                >span{
                    width:75%;
                }
            }
            &.el-col-12{
                >label{
                    width:33.333%;
                }
                >span{
                    width:66.667%;
                }
            }
            &.el-col-8{
                >label{
                    width:50%;
                }
                >span{
                    width:50%;
                }
            }
            &.el-col-6{
                >label{
                    width:66.5%;
                }
                >span{
                    width:33.5%;
                }
            }
        }
    }
}
