/*
*   qilin组件通用类名less样式类
*/

@import "assets/less/_base.less";
@import "assets/less/element.less";
@import "qilin-web/css/qilin-icon.less";

// 外边距、内边距辅助类
.distance(@num,@attribute){
    .mixin(@attribute) when (@attribute = m){
        .qilin-@{attribute}-@{num}{
            margin:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = ml){
        .qilin-@{attribute}-@{num}{
            margin-left:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mr){
        .qilin-@{attribute}-@{num}{
            margin-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mt){
        .qilin-@{attribute}-@{num}{
            margin-top:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mb){
        .qilin-@{attribute}-@{num}{
            margin-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = mx){
        .qilin-@{attribute}-@{num}{
            margin-left:@mp-mini * @num !important;
            margin-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = my){
        .qilin-@{attribute}-@{num}{
            margin-top:@mp-mini * @num !important;
            margin-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = p){
        .qilin-@{attribute}-@{num}{
            padding:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pl){
        .qilin-@{attribute}-@{num}{
            padding-left:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pr){
        .qilin-@{attribute}-@{num}{
            padding-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pt){
        .qilin-@{attribute}-@{num}{
            padding-top:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = pb){
        .qilin-@{attribute}-@{num}{
            padding-bottom:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = px){
        .qilin-@{attribute}-@{num}{
            padding-left:@mp-mini * @num !important;
            padding-right:@mp-mini * @num !important;
        }
    }
    .mixin(@attribute) when (@attribute = py){
        .qilin-@{attribute}-@{num}{
            padding-top:@mp-mini * @num !important;
            padding-bottom:@mp-mini * @num !important;
        }
    }
    // 执行函数
    .mixin(@attribute);
};

// 疑似新版本被弃用
// .loop();
// .loop(@i:0) when (@i <= 20){
// 	.distance(@i,m);
// 	.distance(@i,ml);
// 	.distance(@i,mr);
// 	.distance(@i,mt);
// 	.distance(@i,mb);
// 	.distance(@i,mx);
// 	.distance(@i,my);
// 	.distance(@i,p);
// 	.distance(@i,pl);
// 	.distance(@i,pr);
// 	.distance(@i,pt);
// 	.distance(@i,pb);
// 	.distance(@i,px);
// 	.distance(@i,py);
// 	.loop(@i+1);
// };


each(range(20),{
    .distance(@value,m);
	.distance(@value,ml);
	.distance(@value,mr);
	.distance(@value,mt);
	.distance(@value,mb);
	.distance(@value,mx);
	.distance(@value,my);
	.distance(@value,p);
	.distance(@value,pl);
	.distance(@value,pr);
	.distance(@value,pt);
	.distance(@value,pb);
	.distance(@value,px);
	.distance(@value,py);
});


// 普通样式文本
.qilin-text-info{
    color:@color-text-info !important;
}
// 成功样式文本
.qilin-text-success{
    color:@color-text-success !important;
}
// 警告样式文本
.qilin-text-warning{
    color:@color-text-warning !important;
}
// 错误样式文本
.qilin-text-error{
    color:@color-text-error !important;
}
// 普通样式背景色
.qilin-bg-info{
    background-color:@color-text-shadow-info !important;
    color:@color-text-info !important;
}
// 成功样式背景色
.qilin-bg-success{
    background-color:@color-text-shadow-success !important;
    color:@color-text-success !important;
}
// 警告样式背景色
.qilin-bg-warning{
    background-color:@color-text-shadow-warning !important;
    color:@color-text-warning !important;
}
// 错误样式背景色
.qilin-bg-error{
    background-color:@color-text-shadow-error !important;
    color:@color-text-error !important;
}
// 通用面板动态样式
.qilin-panel{
    background-color:var(--themeBgColor) !important;
    border-color:var(--themeBorderColor) !important;
}


// 圆角样式
.qilin-radius-default{
    border-radius:@radius-default !important;
}
.qilin-radius-round{
    border-radius:@radius-round !important;
}
.qilin-radius-circle{
    border-radius:@radius-circle !important;
}

// 字体加粗等样式
.qilin-text-bolder{
    font-weight:bolder !important;
}
.qilin-text-normal{
    font-weight:normal !important;
}
.qilin-text-lighter{
    font-weight:lighter !important;
}

// 背景阴影样式
.qilin-shadow{
    box-shadow:@rem2 @rem2 @rem5 var(--shadow) !important;
}

:root{
	--themeColor:@color-text-common; //字体色
	--themeBgColor:@color-bg-common; //背景色
	--themeBorderColor:@color-border; //边框色
	--shadow:@color-shadow; //阴影色
}
html,body,#app{
    font-size:87.5% !important; //浏览器默认大小为16px，本项目设置根元素默认字体大小为14px
}
// 两种url路径方式都可实现
html,body,#app{
	.EDGQilinWH();
	.EDGQilinBGImage("@/assets/images/pc1.jpeg");
	// .EDGQilinBGImage("./assets/images/pc1.jpeg");
	position:relative;
	font-family: @text-china-default,@text-english-default;
	color:@color-text-common;
	// overflow:hidden;
}
#app{
	>a{
		color:rgba(255,255,255,.65);
		text-decoration: none;
		position:absolute;
		bottom:20px;
		left:50%;
		transform:translateX(-50%);
		font-size:@size-text-small;
	}
}
*{
	.EDGQilinGeneral()
}

@media only screen and (min-width:1921px){
	html,body,#app{
		font-size:120% !important;
	}
}
@media only screen and (min-width:1537px) and (max-width:1920px){
	html,body,#app{
		font-size:100% !important;
	}
}
@media only screen and (max-width:640px){
	html,body,#app{
		font-size:75% !important;
	}
}
